<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <listitems
          :list="users_list"
          :title="'Liste des Intervenants'"
          :headers="headers"
          :key="aff1"
          :showedit="false"
          :master="true"
          :add="false"
          :del="false"
          :Total="true"
          :filename="'Intervenants'"
          :sheetname="'Intervenants'"
          @rowselect="UserChange"
          @hrf="show_list"
          :laoding="loading"
        >
        </listitems>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <listitems
          :list="trans_internes"
          :title="'Transactions Internes'"
          :headers="transheaders"
          :key="aff2"
          :showedit="false"
          :master="true"
          :add="user.owner == 1"
          :del="false"
          :Total="true"
          :filename="'Transactions'"
          :sheetname="'Transactions'"
          @rowselect="TransChange"
          @open="TransOpen"
          @hrf="TransOpen"
          :laoding="loading"
        >
        </listitems>
      </v-col>
    </v-row>

    <formlist
      :title="title"
      :list="trans_list"
      :showform="!isListClose"
      :listheaders="listheaders"
      :key="fl"
      @close="closeListForm"
      :width="width"
      :Total="true"
      :master="true"
      :kid="kid"
      :orientation="orientation"
    >
    </formlist>
    <transform
      :item="transaction"
      :showForm="!isTransClosed"
      :currencies="currencies"
      :users="users_list"
      :modes="modes"
      :contacts="contacts"
      :trans_type="'i'"
      @close="isTransClosed = true"
      @validate="trans_valid"
      :key="ktf"
      :modify="
        ((transaction.statut_id == 1 && transaction.active == 1) ||
          transaction.id < 0) &&
        editer
      "
    >
    </transform>
  </v-container>
</template>

<script>
import USERS from "../graphql/Users/USERS.gql";
import USER_TRANSACTION from "../graphql/Stat/USER_TRANSACTION.gql";

export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    formlist: () => import("../components/FormList.vue"),
    transform: () => import("../components/TransForm.vue"),
  },

  data: () => ({
    aff1: 1,
    aff2: 100,
    ktf: 2000,
    klist: 0,
    editer: false,
    orientation: "p",
    width: "1000px",
    title: "",
    isListClose: true,
    isTransClosed: true,
    fl: 200,
    list: [],
    user: {},
    transaction: {},
    currencies: [],
    modes: [],
    contacts: [],
    loading: true,
    listheaders: [
      {
        text: "Code",
        align: "start",
        value: "no_opr",
        selected: true,
        index: 0,
      },
      {
        text: "Operateur",
        align: "start",
        value: "tier_name",
        selected: true,
        index: 1,
      },

      {
        text: "Date Opr",
        value: "opr_date",
        selected: true,
        slot: "date",
        index: 3,
      },
      {
        text: "Date Max",
        value: "date_limite",
        selected: false,
        slot: "date",
        expire: true,
        index: 3,
      },
      {
        text: "Montant",
        value: "montant",
        selected: false,
        slot: "cur",
        align: "end",
        index: 4,
      },
      {
        text: "Monnaie",
        value: "currency",
        selected: false,
        index: 5,
      },

      {
        text: "Montant DA",
        value: "mont_dz",
        selected: true,
        total: "mont_dz",
        slot: "cur",
        align: "end",
        index: 6,
      },
      {
        text: "Mode",
        value: "type_paye",
        selected: true,
        index: 7,
      },

      {
        text: "Autre",
        value: "autre",
        slot: "chip",
        color: "autre_color",
        selected: true,
        index: 9,
      },
      {
        text: "Status",
        value: "status_name",

        slot: "chip",
        color: "status_color",
        selected: true,
        index: 10,
      },
    ],
    headers: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Proprietaire",
        value: "owner",
        slot: "checkbox",
        selected: true,
      },

      {
        text: "Nom",
        value: "name",
        selected: true,
      },
      {
        text: "Email",
        value: "email",
        selected: false,
      },
      {
        text: "Tel.",
        value: "tel",
        selected: false,
      },
      {
        text: "Sit.Init.",
        value: "sit_init",
        slot: "cur",
        align: "end",
        total: "sit_init",
        width: "120",
        selected: true,
      },
      {
        text: "Transfere (تسليم)",
        value: "debit",
        align: "end",
        slot: "href3",
        total: "debit",
        width: "120",
        selected: true,
      },
      {
        text: "Reçu (استلام)",
        value: "credit",
        align: "end",
        slot: "href3",
        total: "credit",
        width: "120",
        selected: true,
      },
      {
        text: "Solde",
        value: "solde",
        align: "end",
        slot: "cur",
        total: "solde",
        width: "120",
        selected: true,
      },
    ],
    transheaders: [
      {
        text: "Code",
        align: "start",
        value: "no_opr",
        slot: "href",
        selected: true,
        index: 0,
      },
      {
        text: "Operation",
        value: "operation",
        enum: "OPERATION",
        slot: "chip",
        color: "color",
        selected: true,
        index: 2,
      },
      {
        text: "Caisse",
        value: "user_name",
        selected: true,
        index: 8,
      },
      {
        text: "Date Opr",
        value: "opr_date",
        selected: false,
        slot: "date",
        index: 3,
      },
      {
        text: "Montant",
        value: "montant",
        selected: true,
        slot: "cur",
        align: "end",
        index: 4,
      },
      {
        text: "Monnaie",
        value: "currency",
        selected: true,
        index: 5,
      },

      {
        text: "Montant DA",
        value: "mont_dz",
        selected: true,
        slot: "cur",
        align: "end",
        index: 6,
      },
      {
        text: "Mode",
        value: "type_paye",
        selected: false,
        index: 7,
      },

      {
        text: "Status",
        value: "status_name",
        enum: "STATUS_NAME",
        slot: "chip",
        color: "status_color",
        selected: true,
        index: 10,
      },
    ],
    kid: "id",
    users_list: [],
    trans_list: [],
    departements: [],
    structures: [],
    options: {},
    first: 10,
  }),

  computed: {
    trans_internes() {
      let l = [];
      if (this.user.trans_internes) l = this.user.trans_internes;
      return l;
    },
  },
  watch: {},
  created() {},

  async mounted() {
    let r = await this.requette(USERS);
    if (r) {
      this.users_list = r.users.filter((elm) => elm.id != 1);
      this.currencies = r.currencies;
      this.modes = r.modes;
      this.contacts = r.contacts;
      this.aff1++;
    }
    this.editer =
      this.$store.state.auth.includes("02003") || this.$store.state.isadmin;
  },

  methods: {
    closeListForm() {
      this.isListClose = true;
    },
    async show_list(item) {
      let r = await this.requette(USER_TRANSACTION, {
        user_id: item.id,
        sens: item.col == "credit" ? 1 : -1,
      });
      if (r) {
        this.trans_list = r.user_transactions;
        this.title =
          item.col == "credit" ? this.headers[7].text : this.headers[6].text;
        this.isListClose = false;
        this.fl++;
      }
    },
    trans_valid() {
      this.$store.dispatch("Changed", true);
      this.$emit("refresh", this.editedItem);
    },
    async requette(query, v, f) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: f,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    UserChange(item) {
      this.user = item;
      this.aff2++;
    },
    TransOpen(item) {
      this.transaction = item;
      this.isTransClosed = false;
      this.ktf++;
    },
    TransChange(item) {
      this.transaction = item;
    },
    sortBy(prop) {
      return function (a, b) {
        return a[prop].localeCompare(b[prop]);
      };
    },
  },
};
</script>

<style></style>
